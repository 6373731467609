import logo from './assets/vente_logo.svg';
import imgGP from './assets/google-play.png';
//import imgAS from './assets/appstore.png';
import imgAS from './assets/appstore.png';
import mockup from './assets/mockupiphonevente.png';
import screen from './assets/vente_logo.svg';

import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Col,Row, Button } from 'react-bootstrap';
import './App.css';
import { useState, useEffect } from "react";
import { isMobile, isIOS, isMacOs, deviceDetect,isAndroid } from "react-device-detect";
function App() {
  let backgroundString = `linear-gradient(transparent, rgb(0, 0, 0) 100%) center center / cover no-repeat, linear-gradient(to top, transparent, rgb(0, 0, 0) 100%), url(`+process.env.PUBLIC_URL+`"/assets/media/venteweb.webp")`;
    const [size, setSize] = useState({
      x: window.innerWidth,
      y: window.innerHeight,
      isMobile: window.innerWidth < 768
    });
    const googlePlayURL = "https://play.google.com/store/apps/details?id=com.vente.vente"
    const appstoreURL = "https://apps.apple.com/es/app/vente-tienes-planes/id1541363577"
    const updateSize = () =>{
      setSize({
        x: window.innerWidth,
        y: window.innerHeight,
        isMobile: window.innerWidth<768
      });
      
    //console.log("window.innerWidth",window.innerWidth,"window.innerHeight",window.innerHeight);
    /*
    @media (min-width: 768px)
        .col-md-6 {
    */
      if(window.innerWidth>768){
        document.body.style.background = backgroundString;
        //console.log("document.body.className", document.body.className)
        document.body.className = "SUU";
        //document.body.style["background-repeat"] = "no-repeat";
        //document.body.style["background-size"] = "auto";
      }else if(document.body.style.background){
        //delete document.body.style.background;
        document.body.style.background = null;
        document.body.className = null;
      }
      
     // document.body.style.backgroundColor = "green";
    }

    const navigateToExternalLink = (link) =>{
      window.location.href = link;
    }

    useEffect(() => (window.onresize = updateSize), []);
    const redirect = () => {

      const e = window,
          t = (new Date).valueOf(),
          n = (isIOS ? "iOS" : isMacOs ? "Mac" : "normal");
      /*return setTimeout((function() {
          (new Date).valueOf() - t > 150 || (e.location = "iOS" === n || "Mac" === n ? "https://itunes.apple.com/app/xxxx" : "https://play.google.com/store/apps/details?id=com.vente.vente&referrer=/plan/" + i)
      }), 100), (e.location = "vente://plan/" + i)*/

      return (e.location = "iOS" === n || "Mac" === n ? "appstoreURL" : googlePlayURL);

  }
  /*      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
      </header>
      */
     let secondColumStyle = {
      height: '100vh'//size.isMobile ? '80vh' : '100vh'
     };
     let firstColumStyle = {
      height:'100vh',// size.isMobile ? '80vh' : '100vh',
      backgroundPosition: "center",
      paddingLeft:0, 
      paddingRight:0,
     };
     let logoStyle = {
      display: "flex",
      width: "90%"
     };
     let ratioImageAvailable = 574/181
     let maxHeightBtnsAppAvailabe = 0;
     //document.body.style.backgroundColor = "rgb(0, 0, 0)";
     if(size.isMobile){
       if(size.x/size.y < 387/556){
        firstColumStyle.height='70vh';
       }
      
       maxHeightBtnsAppAvailabe = (size.x *0.95*0.95*0.45 -10)* 1.05 / ratioImageAvailable;
      firstColumStyle.background= backgroundString;
     } else if(firstColumStyle.background) {
      delete firstColumStyle.background;
      //document.body.style["background-repeat"] = "no-repeat";
      //document.body.style["background-size"] = "auto";
     }
     if(!size.isMobile){
      document.body.style.background = backgroundString;
      document.body.className = "SUU";
      maxHeightBtnsAppAvailabe = (size.x *0.5*0.95*0.8*0.35 -10)* 1.05 / ratioImageAvailable;
      //document.body.style["background-repeat"] = "no-repeat";
      //document.body.style["background-size"] = "auto";
     }
     //console.log(firstColumStyle);

     
  // <img src={logo} className="App-logo" alt="logo" />
  return (
    <div className="App" >
      <Container fluid>
        <Row >
          <Col xs="12" md="6" style={{height: firstColumStyle.height, paddingLeft:0,  paddingRight:0 }} >
            <div className="App-divcol1" style={firstColumStyle}>
              <header className="App-header1" style={{ height: firstColumStyle.height}}>
                <div className="App-logotext" style={!size.isMobile ? logoStyle:null}>
                  <img src={logo} className="App-logo" alt="logo" />
                  <div className="App-divTitulo">
                    <p  className="Text-logo">{!size.isMobile?"\u00a0\u00a0":""}vente </p>
                  </div >
                  
                </div>
                
                <p  className="App-title">Crea los mejores planes con amigos </p>
                <div className="containerButtons" style={{width : size.isMobile?"100%":"80%"}}>
                  <Button onClick={() =>{
                    redirect();
                  }} variant="info" size="botonStyle" style={{width : size.isMobile?"95%":"80%"}}>Descarga la app ahora!</Button>
                  <div className="App-available" style={{display: "flex", width: size.isMobile?"95%":"80%", marginTop: "20px", alignContent: "center", alignContent: "center", maxHeight: maxHeightBtnsAppAvailabe}}>
                    <img src={imgGP} className="imagefix" onClick={() => navigateToExternalLink(googlePlayURL)} style={{width: size.isMobile? "45%":"35%", height:"auto", paddingRight:"10px"}}/>
                    <img src={imgAS} className="imagefix" onClick={() => navigateToExternalLink(appstoreURL)}  style={{width: size.isMobile? "43%":"33%", height:"auto", paddingLeft:"10px"}} />
                  </div>
                </div>
              </header>
            </div>
            </Col>
          <Col xs="12" md="6" style={secondColumStyle}>
              <div className="App-header2" >
                <div class="circle naranja" id="circle0"></div>
                <div class="circle azul" id="circle1"></div>
                <div class="circle azul" id="circle2"></div>
                <div class="circle naranja" id="circle3"></div>
                <div class="circle azul" id="circle4"></div>
                <div class="circle blanco" id="circle5"></div>
                <div class="circle azul2" id="circle6"></div>
                    <div  className="App-div-mockup" >
                        <div class="trick"></div>
                        <img src={mockup} className="App-mockup" />
                    </div>
              </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

// <img src={process.env.PUBLIC_URL+"/IPHONE 12_mockupMAINVENTE.png"} className="App-mockup" />
export default App;
